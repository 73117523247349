import React from 'react'

const frontend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Frontend Developer</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                    <i className='bx bxl-html5'></i>

                        <div>
                            <h3 className="skills__name">HTML</h3>
                            <span className="skills__level">Advanced</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-css3'></i>

                        <div>
                            <h3 className="skills__name">CSS</h3>
                            <span className="skills__level">Advanced</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-javascript'></i>

                        <div>
                            <h3 className="skills__name">Javascript</h3>
                            <span className="skills__level">Medium</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-react'></i>

                        <div>
                            <h3 className="skills__name">React</h3>
                            <span className="skills__level">Medium</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-bootstrap'></i>

                        <div>
                            <h3 className="skills__name">Bootstrap</h3>
                            <span className="skills__level">Medium</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-git'></i>

                        <div>
                            <h3 className="skills__name">GIT</h3>
                            <span className="skills__level">Medium</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-wordpress'></i>

                        <div>
                            <h3 className="skills__name">WordPress</h3>
                            <span className="skills__level">Advanced</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-redux'></i>

                        <div>
                            <h3 className="skills__name">Express.js</h3>
                            <span className="skills__level">Medium</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-mongodb'></i>

                        <div>
                            <h3 className="skills__name">MongoDB</h3>
                            <span className="skills__level">Medium</span>
                        </div>

                    </div>

                    <div className="skills__data">
                        <i className='bx bxl-nodejs'></i>

                        <div>
                            <h3 className="skills__name">Node.js</h3>
                            <span className="skills__level">Medium</span>
                        </div>

                    </div>

                   

                </div>

            </div>
        </div>

    )
}

export default frontend